import TemplateContents from './TemplateContents';

export default class extends TemplateContents {
    constructor(param) {
        super(param);
    }

    init() {
        super.init();
    }

    reset() {
        super.reset();
        this.setVars();
    }

    destruct() {
        super.destruct();
    }

    setVars() {
        super.setVars();
    }

    setDom() {
        super.setDom();
        this.h1 = document.querySelector('main.cookie_policy h1');
    }

    initEvents() {
        super.initEvents();
    }

    setEvents() {}

    start() {
        this.setValues();
        TweenMax.delayedCall(0.9, () => {
            this.h1.classList.add('show');
        });
    }

    setValues() {
        this.setEvents();
    }

    scrollHandler() {}

    enterframe() {}

    enterframeThinOut() {}

    executeResize() {
        super.executeResize();
    }
}
